/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layouts/layout';
import SEO from '../components/seo';
import Share from '../components/share';

export const query = graphql`
  query GetNewsletterConfirmationPageSettings($locale: String!) {
    pageSettings: contentfulNewsletterSubscriptionPageConfig(node_locale: { eq: $locale }) {
      title
      description {
        description
      }
      keywords
      confirmationHeading
      confirmationFirstParagraph
      confirmationSecondParagraph
    }
  }
`;
function NewsletterSignUpConfirmPage({ data }) {
  const { pageSettings } = data;
  return (
    <Layout>
      <SEO
        title={pageSettings.title}
        description={pageSettings.description.description}
        keywords={pageSettings.keywords}
        robots="noindex"
      />

      <div className="mx-auto md:w-1/2 text-center">
        <h1 className="mb-8">{pageSettings.confirmationHeading}</h1>
        <p className="text-xl font-bold">{pageSettings.confirmationFirstParagraph}</p>
        <p className="text-xl my-4">{pageSettings.confirmationSecondParagraph}</p>
        <Share
          url={process.env.SITE_URL}
          title="Europe's fastest exclusively remote job board is launching soon!"
          description="Digital nomads & remote workers assemble! In a very short time, Europe will have its own exclusively-remote job board. Love remote work? Me too! So I joined the weremote.eu newsletter."
          campaignOptions={{
            source: 'website',
            medium: 'newsletter_signup',
            campaign: 'spread_the_word',
          }}
        />
      </div>
    </Layout>
  );
}

export default NewsletterSignUpConfirmPage;
